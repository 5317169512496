import React from 'react';
import { FormControl, FormLabel, Input, Select, FormErrorMessage } from '@chakra-ui/react';
import { Field } from 'formik';
import { ABTest } from '@repo/alictus-common/types/ab_test';
import { Platform } from '@repo/alictus-common/enums/platform';

export function BasicInfo({ abTestModal, updateAbTestModal }: { abTestModal: ABTest; updateAbTestModal: (test: Partial<ABTest>) => void }) {
	return (
		<>
			<Field name="name">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl isInvalid={form.errors.name && form.touched.name}>
						<FormLabel>A/B Name</FormLabel>
						<Input
							{...field}
							value={abTestModal?.name}
							onChange={(e) => {
								form.setFieldValue('name', e.target.value);
								updateAbTestModal({ name: e.target.value });
							}}
						/>
						<FormErrorMessage>{form.errors.name}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="description">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl isInvalid={form.errors.description && form.touched.description} mt={4}>
						<FormLabel>Experiment Description</FormLabel>
						<Input
							{...field}
							value={abTestModal?.description}
							onChange={(e) => {
								form.setFieldValue('description', e.target.value);
								updateAbTestModal({ description: e.target.value });
							}}
						/>
						<FormErrorMessage>{form.errors.description}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="platform">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} isInvalid={form.errors.platform && form.touched.platform}>
						<FormLabel>Select Platform</FormLabel>
						<Select
							{...field}
							value={abTestModal?.platform}
							onChange={(e) => {
								updateAbTestModal({ platform: parseInt(e.target.value) });
								form.setFieldValue('platform', parseInt(e.target.value));
							}}
							placeholder="Select platform"
						>
							<option value={Platform.iOS}>iOS</option>
							<option value={Platform.Android}>Android</option>
						</Select>
						<FormErrorMessage>{form.errors.platform}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default BasicInfo;
