import React, { useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Stack,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';
import { DataSelector } from '@repo/alictus-common/types/segment';
import { DataSource } from '@repo/alictus-common/enums/data_source';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import dataSelectorSchema from '@repo/alictus-common/validation/data_selector_schema';

import { Field, Form, Formik, FormikValues } from 'formik';

const createEmptyDataSelector = (): DataSelector => {
	return {
		id: 0,
		creationDate: 0,
		isDeleted: false,
		isOverWritten: false,
		lastUpdated: 0,
		uid: '',
		userId: 0,
		name: '',
		description: '',
		dataSource: DataSource.Playfab,
		fieldName: '',
		isDate: false,
	};
};

export function SelectorModal({
	isOpen,
	onClose,
	selectorInstance,
}: {
	isOpen: boolean;
	onClose: () => void;
	selectorInstance?: DataSelector;
}) {
	let globalData = globalStore();
	const toast = useToast();
	const [dataSelector, setDataSelector] = useState<DataSelector>(createEmptyDataSelector());
	const [showSubmitButton, setShowSubmitButton] = useState(true);
	const [playfabUserId, setPlayfabUserId] = useState('');
	const [playfabUserData, setPlayfabUserData] = useState<string[]>([]);
	const [isFetching, setIsFetching] = useState(false);
	const [isDate, setIsDate] = useState(false);

	useEffect(() => {
		if (selectorInstance) {
			setDataSelector(selectorInstance);
			setIsDate(selectorInstance.isDate);
		}
	}, [selectorInstance]);

	const resetForm = () => {
		setDataSelector(createEmptyDataSelector());
	};

	function updateDataSelector(dataSelectorField: Partial<DataSelector>) {
		setDataSelector({ ...dataSelector, ...dataSelectorField });
		console.log('Updated data selector', dataSelector);
	}

	function handleClose() {
		setShowSubmitButton(true);
		resetForm();
		onClose();
	}

	const handleCreateSelector = async (event?: React.FormEvent) => {
		if (event) event.preventDefault();
		try {
			setShowSubmitButton(false);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
			await axios.post(url, dataSelector, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
				handleClose();
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Creating Selector',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setShowSubmitButton(true);
		}
	};

	const handleUpdateSelector = async (selectorId: number) => {
		try {
			setShowSubmitButton(false);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector/' + selectorId;
			await axios.put(url, dataSelector, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
				handleClose();
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Updating Selector',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setShowSubmitButton(true);
		}
	};

	const processSubmission = async () => {
		if (selectorInstance) {
			await handleUpdateSelector(dataSelector.id);
		} else {
			await handleCreateSelector();
		}
	};

	const onPlayfabDataFetch = async () => {
		setIsFetching(true);
		let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/playfab_data/' + playfabUserId;
		await axios
			.get(url, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } })
			.then((response) => {
				const extractedKeys = Object.keys(response.data);
				setPlayfabUserData(extractedKeys);
			})
			.finally(() => setIsFetching(false));
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				{selectorInstance ? <ModalHeader>Update Selector</ModalHeader> : <ModalHeader>Create Selector</ModalHeader>}
				<ModalCloseButton />
				<Formik
					initialValues={{
						name: selectorInstance?.name || '',
						description: selectorInstance?.description || '',
						fieldName: selectorInstance?.fieldName || '',
					}}
					validationSchema={toFormikValidationSchema(dataSelectorSchema)}
					onSubmit={async (values, actions) => {
						if (selectorInstance === undefined) {
							await handleCreateSelector(selectorInstance);
						}

						if (selectorInstance !== null) {
							await handleUpdateSelector(selectorInstance!.id);
						}
						actions.setSubmitting(false);
					}}
				>
					{(props) => (
						<Form>
							<ModalBody>
								<Stack spacing={4}>
									<Field name="name">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl isInvalid={!!props.errors.name && props.touched.name}>
												<FormLabel>Name</FormLabel>
												<Input
													{...field}
													value={dataSelector.name}
													onChange={(event) => {
														if (event) {
															form.setFieldValue('name', event.target.value);
															updateDataSelector({ name: event.target.value });
														}
													}}
												/>
												<FormErrorMessage>{props.errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="description">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl isInvalid={!!props.errors.description && props.touched.description}>
												<FormLabel>Description</FormLabel>
												<Input
													{...field}
													value={dataSelector.description}
													onChange={(event) => {
														if (event) {
															form.setFieldValue('description', event.target.value);
															updateDataSelector({ description: event.target.value });
														}
													}}
												/>
												<FormErrorMessage>{form.errors.description}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<FormControl>
										<FormLabel>Template Playfab Id</FormLabel>
										<HStack>
											<Input value={playfabUserId} onChange={(event) => setPlayfabUserId(event.target.value)} />
											{isFetching ? <Spinner /> : <Button onClick={onPlayfabDataFetch}>Fetch</Button>}
										</HStack>
									</FormControl>
									<Field name="fieldNamePlayfabID">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl flex="1" minW={'200px'}>
												<Select
													value={dataSelector.fieldName}
													onChange={(e) => {
														form.setFieldValue('fieldName', e.target.value);
														updateDataSelector({ fieldName: e.target.value });
													}}
												>
													{playfabUserData.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</Select>
											</FormControl>
										)}
									</Field>
									<Field name="fieldName">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl isInvalid={!!props.errors.fieldName && props.touched.fieldName}>
												<FormLabel>Selected FieldName</FormLabel>
												<Input
													{...field}
													value={dataSelector.fieldName}
													onChange={(event) => {
														if (event) {
															form.setFieldValue('fieldName', event.target.value);
															updateDataSelector({ fieldName: event.target.value });
														}
													}}
												/>
												<FormErrorMessage>{form.errors.fieldName}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Checkbox
										isChecked={isDate}
										onChange={() => {
											setIsDate(!isDate);
											updateDataSelector({ isDate: !isDate });
										}}
									>
										Is Date
									</Checkbox>
								</Stack>
								<ModalFooter padding={'20px'}>
									<Button variant="outline" mr={3} onClick={onClose}>
										Cancel
									</Button>

									<Button colorScheme="blue" isLoading={props.isSubmitting} isDisabled={!props.isValid} type="submit">
										{selectorInstance === undefined ? 'Create' : 'Update'}
									</Button>
								</ModalFooter>
							</ModalBody>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
}

export default SelectorModal;
