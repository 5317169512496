import { object, string } from "zod";

// Define the updatePasswordFormSchema
const updatePasswordFormSchema = object({
  currentPassword: string().min(8, "Current password must be at least 8 characters"),
  newPassword: string().min(8, "New password must be at least 8 characters"),
  confirmPassword: string().min(8, "Confirm password must be at least 8 characters"),
}).refine((data) => data.currentPassword !== data.newPassword, {
  message: "New password must be different from the current password",
  path: ["newPassword"],
}).refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
});

export default updatePasswordFormSchema;