import { RemoteConfigValue } from '@repo/alictus-common/types';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { Platform } from '@repo/alictus-common/enums/platform';
import { FilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';
import { FilterCondition } from '@repo/alictus-common/types';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

export class ViewFilter {
	public ViewOnlyActive: boolean;
	public ViewIsTestEnvironment: boolean | null;
	public ViewPrivate: boolean | null;
	public ViewVersion: string | null;
	public ExclusiveVersion: boolean;
	public ViewCountry: string | null;
	public ExclusiveCountry: boolean;
	public ViewPlatform: string | null;
	public ExclusivePlatform: boolean;
	public ViewSegmentation: string | null;
	public SegmentationData: PlayerSegment[] | null;

	constructor() {
		this.ViewOnlyActive = true;
		this.ViewVersion = null;
		this.ViewPrivate = null;
		this.ViewIsTestEnvironment = null;
		this.ExclusiveVersion = false;
		this.ViewCountry = null;
		this.ExclusiveCountry = false;
		this.ViewPlatform = null;
		this.ExclusivePlatform = false;
		this.ViewSegmentation = null;
		this.SegmentationData = null;
	}

	setSegmentationData(segmentationData: PlayerSegment[]): ViewFilter {
		this.SegmentationData = segmentationData;
		return this;
	}

	toggleViewOnlyActive(): ViewFilter {
		this.ViewOnlyActive = !this.ViewOnlyActive;
		return this;
	}

	checkIfFilterExists(rcValue: RemoteConfigValue, filterId: number): boolean {
		for (let i = 0; i < rcValue.filterOperations.length; i++) {
			let filterOp = rcValue.filterOperations[i];
			if (filterOp.Filter === filterId) return true;
		}
		return false;
	}

	checkIfSegmentationFilterExists(rcValue: RemoteConfigValue): boolean {
		for (let i = 0; i < rcValue.filterOperations.length; i++) {
			let filterOp = rcValue.filterOperations[i];
			let segmentationIDFromName = this.SegmentationData!.find((segment) => segment.name === this.ViewSegmentation)?.uid;
			if (filterOp.Filter === FilterType.Segmentation && filterOp.Values.includes(segmentationIDFromName!)) return true;
		}
		return false;
	}

	parseVersion = (v: string): [number, number, number] => {
		const [x, y, z] = v.split('.').map(Number);
		return [x, y, z];
	};

	compareVersions = ([aX, aY, aZ]: [number, number, number], [bX, bY, bZ]: [number, number, number]): number => {
		if (aX !== bX) return aX - bX;
		if (aY !== bY) return aY - bY;
		return aZ - bZ;
	};

	checkIfVersionFilterExistsInRange(filterOps: FilterOperation[], filterField: FilterType): boolean {
		if (!this.ViewVersion) {
			return false;
		}

		const [desiredX, desiredY, desiredZ] = this.parseVersion(this.ViewVersion!);
		const versionFilters = filterOps.filter((filterOp) => filterOp.Filter === filterField);

		if (versionFilters.length === 0) {
			return true;
		}

		let isInRange = true;
		for (const filter of versionFilters) {
			for (const value of filter.Values) {
				if (typeof value !== 'string') continue;

				const [filterX, filterY, filterZ] = this.parseVersion(value);
				const comparison = this.compareVersions([desiredX, desiredY, desiredZ], [filterX, filterY, filterZ]);

				switch (filter.Condition) {
					case FilterCondition.Equal:
						return comparison === 0;
					case FilterCondition.NotEqual:
						return comparison !== 0;
					case FilterCondition.GreaterThan:
						isInRange = isInRange && comparison > 0;
						break;
					case FilterCondition.LessThan:
						isInRange = isInRange && comparison < 0;
						break;
					case FilterCondition.GreaterThanOrEqual:
						isInRange = isInRange && comparison >= 0;
						break;
					case FilterCondition.LessThanOrEqual:
						isInRange = isInRange && comparison <= 0;
						break;
					case FilterCondition.Include:
						return comparison === 0;
					case FilterCondition.Exclude:
						return comparison !== 0;
					default:
				}

				if (!isInRange) {
					return false;
				}
			}
		}

		return isInRange;
	}

	canDisplay(rcValue: RemoteConfigValue): boolean {
		//console.log(`ViewOnlyActive: ${this.ViewOnlyActive} and rcValue.isActive: ${rcValue.isActive} `);
		if (this.ViewOnlyActive && !rcValue.isActive) return false;

		if (this.ViewIsTestEnvironment !== null) {
			//console.log(`ViewIsTestEnvironment: ${this.ViewIsTestEnvironment} and rcValue.isTest: ${rcValue.isTest} `);
			if (this.ViewIsTestEnvironment && !rcValue.isTest) return false;
			if (!this.ViewIsTestEnvironment && rcValue.isTest) return false;
		}

		if (this.ViewPrivate !== null) {
			//console.log(`ViewIsTestEnvironment: ${this.ViewIsTestEnvironment} and rcValue.isTest: ${rcValue.isTest} `);
			if (this.ViewPrivate && !rcValue.isPersonal) return false;
			if (!this.ViewPrivate && rcValue.isPersonal) return false;
		}

		if (!this.hasFilter()) return true;

		if (this.ViewVersion && !this.checkIfVersionFilterExistsInRange(rcValue.filterOperations, FilterType.Version)) {
			return false;
		}

		if (this.ViewSegmentation) {
			if (!this.checkIfSegmentationFilterExists(rcValue)) return false;
		}

		if (this.hasExclusiveFilter()) {
			if (this.ViewVersion && this.ExclusiveVersion) {
				if (!this.checkIfFilterExists(rcValue, 2)) return false;
			}

			if (this.ViewCountry && this.ExclusiveCountry) {
				if (!this.checkIfFilterExists(rcValue, 1)) return false;
			}

			if (this.ViewPlatform) {
				const platformId = platformNameToPlatformEnum(this.ViewPlatform.toLowerCase());

				if (this.ExclusivePlatform) {
					return rcValue.platform === platformId;
				}

				return rcValue.platform === platformId || rcValue.platform === Platform.All;
			}
		}

		for (let i = 0; i < rcValue.filterOperations.length; i++) {
			let filterOp = rcValue.filterOperations[i];

			if (this.ViewCountry && filterOp.Filter === FilterType.Country) {
				if (!filterOp.Values.map((value) => value.toString().toLowerCase()).includes(this.ViewCountry!.toLowerCase())) {
					console.log(`ViewCountry filter failed for ${this.ViewVersion} and ${this.ViewCountry}`);
					return false;
				}
			}
			if (this.ViewPlatform) {
				const platformId = platformNameToPlatformEnum(this.ViewPlatform.toLowerCase());
				return rcValue.platform === platformId || rcValue.platform === Platform.All;
			}
		}

		return true;
	}

	hasFilter(): boolean {
		return (
			!!this.ViewVersion ||
			!!this.ViewCountry ||
			!!this.ViewPlatform ||
			this.ViewIsTestEnvironment !== null ||
			this.ViewSegmentation !== null
		);
	}

	hasExclusiveFilter(): boolean {
		return this.ExclusiveVersion || this.ExclusiveCountry || this.ExclusivePlatform;
	}
}

function platformNameToPlatformEnum(platform: string): Platform {
	switch (platform) {
		case 'ios':
			return Platform.iOS;
		case 'android':
			return Platform.Android;
		default:
			return Platform.All;
	}
}
