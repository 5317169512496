import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import ABTestModal from './add_ab_test_modal';
import config from '../config';
import { Icon, ViewIcon } from '@chakra-ui/icons';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import axios from 'axios';
import { formatTimestamp } from '../utility';
import { ABTest, ABTestStreamView } from '@repo/alictus-common/types/ab_test';
import { RemoteConfigValue } from '@repo/alictus-common/types';
import { ABTestState } from '@repo/alictus-common/enums/ab_test_state';
import globalStore from '../store';

function AbTestByteToString(value: any) {
	if (value === 0) return 'Waiting to Start';
	if (value === 1) return 'Running';
	if (value === 2) return 'Completed';
	if (value === 3) return 'No New Users';
	if (value === 4) return 'Cancelled';
	if (value === 5) return 'Paused for Today';
	if (value === 6) return 'Target User Count Reached';
}

function ViewButton({ onOpen }: { onOpen: () => void }) {
	return (
		<IconButton
			onClick={onOpen}
			aria-label="Add"
			icon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
			backgroundColor={'transparent'}
			_hover={{ bg: 'transparent', color: 'white' }}
		/>
	);
}

function ABTestData() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedTest, setSelectedTest] = useState<ABTest | null>(null);
	const [remoteAbTests, setRemoteAbTests] = useState<ABTestStreamView[]>([]);
	let store = globalStore();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let bearerToken = 'Bearer ' + store.JWTToken;
				console.log('Fetching AB tests');
				console.log('Store:', store);
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + store.gameId + '/ab_test/stream/0';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				const abTestStreamViews = response.data as ABTestStreamView[];
				console.log('AB tests:', abTestStreamViews);
				setRemoteAbTests(abTestStreamViews);
			} catch (error) {
				console.error('Error fetching AB tests:', error);
			} finally {
				setLoading(false);
			}
		};

		const fetchRemoteConfigData = async () => {
			try {
				let bearerToken = 'Bearer ' + store.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + store.gameId + '/rc_package/stream/0';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let incomingRemoteConfigValues = response.data as RemoteConfigValue[];
				if (incomingRemoteConfigValues) {
					store.setRemoteConfigValues(incomingRemoteConfigValues);
				}
			} catch (error) {
				console.error('Error fetching remote config data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
		if (store.remoteConfigValues.length === 0) {
			fetchRemoteConfigData();
		}
	}, []);

	const openModal = (test: ABTest) => {
		setSelectedTest(test);
		onOpen();
	};

	const updateAbState = (abTest: ABTest, state: ABTestState) => {
		let url = config.API_ENDPOINT + '/game/' + store.gameId + '/ab_test/' + abTest.id;
		abTest.state = state;
		axios.put(url, abTest, { headers: { Authorization: 'Bearer ' + store.JWTToken } }).then((response) => {
			console.log('AB Test updated successfully:', response.data);
			setRemoteAbTests(
				remoteAbTests.map((abTestStreamView) => {
					if (abTestStreamView.abTest.id === abTest.id) {
						abTestStreamView.abTest = abTest;
					}
					return abTestStreamView;
				}),
			);
		});
	};

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<Spinner size="xl" />
			</Box>
		);
	}

	return (
		<Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th>Name</Th>
						<Th>Description</Th>
						<Th>Id</Th>
						<Th>Platform</Th>
						<Th>Activation Date</Th>
						<Th>Total Users</Th>
						<Th>Daily Users</Th>
						<Th>End Date</Th>
						<Th>State</Th>
						<Th textAlign="center" align={'center'} width={'20px'}>
							View
						</Th>
						<Th>Action</Th>
					</Tr>
				</Thead>
				<Tbody>
					{remoteAbTests.map(({ abTest: test, stats: stats }: ABTestStreamView) => (
						<Tr key={test.id}>
							<Td>{test.name}</Td>
							<Td>{test.description}</Td>
							<Td>{test.uid}</Td>
							<Td>{test.platform}</Td>
							<Td>{formatTimestamp(test.activationDate)}</Td>
							<Td>{stats === null ? 0 : stats.totalAssignmentCount}</Td>
							<Td>{stats === null ? 0 : stats.dailyAssignmentCount}</Td>
							<Td>{formatTimestamp(test.endDate)}</Td>
							<Td>{AbTestByteToString(test.state)}</Td>
							<Td textAlign="center" align={'center'} width={'20px'}>
								<Button
									onClick={() => openModal(test)}
									leftIcon={<Icon as={ViewIcon} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
									variant="simple"
								/>
							</Td>
							<Td>
								{test.state !== ABTestState.Cancelled && test.state !== ABTestState.Completed ? (
									<Menu>
										<MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
											<IconButton
												onClick={onOpen}
												aria-label="Add"
												icon={<PiDotsThreeOutlineVerticalFill color={'gray.500'} />}
												backgroundColor={'transparent'}
												_hover={{ bg: 'transparent', color: 'white' }}
											/>
										</MenuButton>
										<MenuList>
											{test.state === ABTestState.WaitingToStart && (
												<MenuItem onClick={() => updateAbState(test, ABTestState.Running)}>Start Test</MenuItem>
											)}
											{(test.state === ABTestState.Running ||
												test.state === ABTestState.PausedForToday ||
												test.state === ABTestState.NoNewUser ||
												test.state === ABTestState.WaitingToStart) && (
												<MenuItem onClick={() => updateAbState(test, ABTestState.Cancelled)}>Cancel Test</MenuItem>
											)}
											{test.state === ABTestState.Running && (
												<MenuItem onClick={() => updateAbState(test, ABTestState.NoNewUser)}>Stop Acquiring Users</MenuItem>
											)}
											{(test.state === ABTestState.Running ||
												test.state === ABTestState.NoNewUser ||
												test.state === ABTestState.PausedForToday ||
												test.state === ABTestState.TargetUserCountReached) && (
												<MenuItem onClick={() => updateAbState(test, ABTestState.Completed)}>Mark as Complete</MenuItem>
											)}
										</MenuList>
									</Menu>
								) : test.state === ABTestState.Cancelled ? (
									'Cancelled'
								) : (
									'Completed'
								)}
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
			{isOpen && <ABTestModal title={'Edit AB Test'} isOpen={isOpen} onClose={onClose} abTestToUpdate={selectedTest} />}
		</Box>
	);
}

function ABTestView({ userToken, selectedGameId }: { userToken: string; selectedGameId: string | null }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Grid
			height={'1px'}
			width={'100%'}
			templateRows="repeat(4, 1fr)"
			templateColumns="repeat(5, 1fr)"
			gap={2}
			paddingLeft={'12px'}
			paddingRight={'10px'}
		>
			<GridItem colSpan={6} padding={'15px'}>
				{userToken && selectedGameId && isOpen && (
					<ABTestModal title={'Create AB Test'} isOpen={isOpen} onClose={onClose} abTestToUpdate={null} />
				)}
				{selectedGameId !== null && (
					<Flex justifyContent="flex-end">
						<Button colorScheme="blue" size="xs" onClick={onOpen}>
							Create A/B Test
						</Button>
					</Flex>
				)}
			</GridItem>
			<GridItem paddingLeft={'20px'} colSpan={6}>
				{selectedGameId !== null && <ABTestData />}
			</GridItem>
		</Grid>
	);
}

export default ABTestView;
