import { z } from 'zod';
import { FilterCondition } from '../types/enums/filter_condition';
import { DateFilter } from '../types/enums/date_filter';

const segmentationSchema = z
	.object({
		name: z.string().min(3, 'Name must be at least 3 characters'),
		description: z.string().min(3, 'Description must be at least 3 characters'),
		filterOperations: z
			.array(
				z.object({
					SelectorUid: z.string(),
					Condition: z.nativeEnum(FilterCondition).refine((val) => val !== FilterCondition.NoOp, {
						message: 'Filter condition cannot be NoOp',
					}),
					Values: z.array(z.string()),
					DateFilter: z.nativeEnum(DateFilter).optional(),
				}),
			)
			.min(1, 'At least one filter operation is required')
			.nonempty(),
	})
	.superRefine((data, ctx) => {
		const { filterOperations } = data;

		filterOperations.forEach((filterOperation) => {
			const { DateFilter } = filterOperation;

			if (DateFilter !== undefined && DateFilter > 0) {
				const { Values } = filterOperation;

				Values.forEach((value) => {
					if (!Number.isInteger(Number(value))) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: 'Value must be an integer for date filter',
						});
					}
				});
			}
		});
	});

export default segmentationSchema;
