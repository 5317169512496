export var FilterType;
(function (FilterType) {
    FilterType[FilterType["None"] = 0] = "None";
    FilterType[FilterType["Country"] = 1] = "Country";
    FilterType[FilterType["Version"] = 2] = "Version";
    FilterType[FilterType["DeviceId"] = 3] = "DeviceId";
    FilterType[FilterType["Platform"] = 4] = "Platform";
    FilterType[FilterType["Date"] = 5] = "Date";
    FilterType[FilterType["BuildNumber"] = 6] = "BuildNumber";
    FilterType[FilterType["NewUser"] = 7] = "NewUser";
    FilterType[FilterType["AbTest"] = 8] = "AbTest";
    FilterType[FilterType["PlayFab"] = 9] = "PlayFab";
    FilterType[FilterType["PlayFabCustom"] = 10] = "PlayFabCustom";
    FilterType[FilterType["Segmentation"] = 11] = "Segmentation";
})(FilterType || (FilterType = {}));
export var FilterTypeString;
(function (FilterTypeString) {
    FilterTypeString["Country"] = "Country";
    FilterTypeString["Version"] = "Version";
    FilterTypeString["DeviceId"] = "DeviceId";
    FilterTypeString["Platform"] = "Platform";
    FilterTypeString["Date"] = "Date";
    FilterTypeString["BuildNumber"] = "BuildNumber";
    FilterTypeString["NewUser"] = "NewUser";
    FilterTypeString["AbTest"] = "AbTest";
})(FilterTypeString || (FilterTypeString = {}));
