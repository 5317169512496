import axios from 'axios';
import config from '../../config';
import { GlobalStore } from '../../store';
import { RemoteConfigValue } from '@repo/alictus-common/types/remote_config';

export function toggleRCValueStatus(globalData: GlobalStore, rcValueId: number): void {
    console.log("toggleRCValueStatus");

    axios.put(
        `${config.API_ENDPOINT}/game/${globalData.gameId}/rc_package/1/rc_value/${rcValueId}/toggle`,
        {},
        { headers: { Authorization: `Bearer ${globalData.JWTToken}` } }
    )
    .then((response) => {
        const updatedValue = response.data as RemoteConfigValue;
        const updatedValues = globalData.remoteConfigValues.map(value => 
            value.uid === updatedValue.uid ? updatedValue : value
        );
        globalData.setRemoteConfigValues(updatedValues);
    })
    .catch(error => {
        console.error("Error toggling RC value status:", error);
    });
}
