import { z } from 'zod';
import { DataSelector } from '../types/segment';
import { DataSource } from '../types/enums/data_source';

const dataSelectorSchema = z.object({
	name: z.string().min(3, 'Name must be at least 3 characters long'),
	description: z.string().min(3, 'Description must be at least 3 characters long'),
	fieldName: z.string().min(3, 'Field name must be at least 3 characters long'),
});

export default dataSelectorSchema;
