import { FilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';
import { FilterType } from '@repo/alictus-common/enums/filter_type';
import { FilterCondition } from '@repo/alictus-common/enums/filter_condition';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

export function FilterToString(filterOperations: FilterOperation[], segmentationData: PlayerSegment[]): string {
	return filterOperations
		.map((filterOperation) => {
			const filterString = FilterType[filterOperation.Filter];
			const conditionString = FilterCondition[filterOperation.Condition];
			let valuesString = '';

			if (filterOperation.Filter === FilterType.Segmentation && segmentationData !== undefined && segmentationData.length > 0) {
				const segmentId = filterOperation.Values[0];
				const segment = segmentationData.find((segment) => segment.uid === segmentId);
				const segmentName = segment?.name || '';

				valuesString = segmentName;
			} else {
				valuesString = filterOperation.Values.join(', ');
			}

			return `Filter: ${filterString}, Condition: ${conditionString}, Values: [${valuesString}]`;
		})
		.join('\n');
}
