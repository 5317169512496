import React, { useState } from 'react';
import {
	Button,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';


export function GetAlictusIdModal({
																isOpen,
																onClose
															}: {
	isOpen: boolean;
	onClose: () => void;
}) {
	let globalData = globalStore();
	const [playfabUserId, setPlayfabUserId] = useState('');
	const [deviceId, setDeviceId] = useState('');
	const [isFetching, setIsFetching] = useState(false);

	const onPlayfabDataFetch = () => {
		setIsFetching(true);
		let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/playfab_data/' + playfabUserId;
		axios
			.get(url, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } })
			.then((response) => {
				try{
					const deviceId = response.data["PlayFab.Value.DeviceID"];
					const cleanedDeviceId = deviceId.replace(/-/g, '');
					setDeviceId(cleanedDeviceId);
				}
				catch(error){
          console.error("Error fetching Playfab data:", error);
        }
			})
			.finally(() => setIsFetching(false));
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent height={200}>
				<ModalHeader>Get Playfab Id From Device Id</ModalHeader>
				<ModalCloseButton />
				<form>
					<ModalBody>
						<Stack spacing={4}>
							<FormControl>
								<FormLabel>Playfab Id</FormLabel>
								<HStack>
									<Input value={playfabUserId} onChange={(event) => setPlayfabUserId(event.target.value)} />
									{isFetching ? <Spinner /> : <Button onClick={onPlayfabDataFetch}>Fetch</Button>}
								</HStack>
							</FormControl>
							{deviceId && (
								<FormControl>
									<FormLabel>Device Id: {deviceId}</FormLabel>
								</FormControl>
							)}
						</Stack>
					</ModalBody>
				</form>
			</ModalContent>
		</Modal>
	);
}

export default GetAlictusIdModal;
