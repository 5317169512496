import { z } from 'zod';
import { Platform } from '../types';
import { UserTargeting } from '../types';

const abTestValidationSchema = z
	.object({
		name: z.string().min(1, 'Experiment Name is required'),
		description: z.string().min(1, 'Experiment Description is required'),
		platform: z.nativeEnum(Platform, { message: 'Platform is required' }),
		userTargeting: z.nativeEnum(UserTargeting, { message: 'User targeting is required' }),
		countryFilter: z.number().optional(),
		country: z
			.array(
				z.object({
					value: z.string().min(1, 'Country value is required'),
					label: z.string().min(1, 'Country label is required'),
				}),
			)
			.nullable()
			.optional(),

		versionFilter: z.number().optional(),
		version: z.string().nullable().optional(),
		buildNumber: z.number().nonnegative().min(0, { message: 'Build number must be non-negative' }).optional(),
		buildNumberFilter: z.number().optional(),
		variants: z
			.array(
				z.object({
					name: z.string().min(1, 'Variant name is required'),
					values: z
						.array(
							z.object({
								key: z.string().min(1, 'RC key is required'),
								value: z.any().refine((val) => val !== undefined, {
									message: 'RC value is required',
								}),
							}),
						)
						//.optional(),
						.nonempty(),
				}),
			)
			//.optional(),
			.min(2, { message: 'At least two variants are required' }),

		state: z
			.number()
			.nonnegative()
			.refine((val) => val !== undefined, {
				message: 'State is required',
			}),
		dailyUserLimit: z.number().nonnegative().min(1, { message: 'Daily user limit must be at least 1' }),
		totalMaximumUserCount: z.number().nonnegative().min(1, { message: 'Total maximum user count must be at least 1' }),
	})
	.superRefine((data, ctx) => {
		if (
			(data.countryFilter === 7 || data.countryFilter === 8) &&
			(data.country === null || data.country === undefined || data.country.length === 0)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If country filter is selected, at least one country must be selected',
				path: ['country'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.countryFilter === 0 && data.country && data.country.length > 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If country is selected, a country filter must be selected',
				path: ['country'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.version && data.version.length > 0) {
			let splitted_version = data.version.split('.');
			if (
				splitted_version.length !== 3 ||
				splitted_version.some((v) => !/^\d+$/.test(v)) ||
				splitted_version.some((v) => parseInt(v) < 0)
			) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Version must be in the format x.y.z where x, y, z are nonnegative integers',
					path: ['version'],
				});
			}

			if (data.versionFilter === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'If version is entered, a version filter must be selected',
					path: ['version'],
				});
			}
		}

		if (data.versionFilter !== 0 && (!data.version || data.version.length === 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If version filter is selected, a version must be entered',
				path: ['version'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.buildNumber) {
			if (isNaN(data.buildNumber) || data.buildNumber < 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Build number must be a nonnegative integer',
					path: ['buildNumber'],
				});
			}

			if (data.buildNumberFilter === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'If build number is entered, a build number filter must be selected',
					path: ['buildNumber'],
				});
			}
		}

		if (data.buildNumberFilter !== 0 && (!data.buildNumber || data.buildNumber <= 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'If build number filter is selected, a build number must be entered',
				path: ['buildNumber'],
			});
		}
	})
	.superRefine((data, ctx) => {
		if (data.dailyUserLimit && data.totalMaximumUserCount && data.dailyUserLimit > data.totalMaximumUserCount) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Daily user limit must be less than or equal to total maximum user count',
				path: ['dailyUserLimit'],
			});
		}
	});

export default abTestValidationSchema;
