//const BASE_URL = 'dash.v1.alictuscloud.com';
//const BASE_URL = 'dash.v1.alictuscloud.com';
//const BASE_URL = 'logger.alictusapi.com:54290';
const BASE_URL = 'dashv2-backend-prod.alictuscloud.com';
const LOGGER_URL = 'logger.alictusapi.com:54295';
//const API_ENDPOINT = `https://${BASE_URL}`;
const API_ENDPOINT = `https://${BASE_URL}`;
const LOGGER_ENDPOINT = `https://${LOGGER_URL}`;
const WEBSOCKET_ENDPOINT = `wss://${LOGGER_URL}`;
const LOGGER_WEBSOCKET_ENDPOINT = `wss://${LOGGER_URL}`;
const CLOUDFLARE_LOCAL_ENDPOINT = `https://${BASE_URL}`;

const config = {
	API_ENDPOINT,
	LOGGER_ENDPOINT,
	WEBSOCKET_ENDPOINT,
	LOGGER_WEBSOCKET_ENDPOINT,
	CLOUDFLARE_LOCAL_ENDPOINT,
};

export default config;
