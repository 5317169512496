import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Box, Text, Flex, Textarea, Icon } from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { FaTrashAlt } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { RemoteConfigValue } from '@repo/alictus-common/types';
import { ViewFilter } from '../view_filter';
import { GlobalStore } from '../../store';
import { RemoteConfigTypeToString, formatTimestamp } from '../../utility';
import { FilterToString } from '../utils/filter_utils';
import { toggleRCValueStatus } from '../utils/remote_config_utils';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

interface RemoteConfigTableProps {
	globalData: GlobalStore;
	viewFilter: ViewFilter;
	editRCVariableDisclosure: any;
	deleteRCKeyDisclosure: any;
	setDeleteRCKeyData: (data: any) => void;
	multiFunction: (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => void;
	segmentationData: PlayerSegment[];
}

export function RemoteConfigTable({
	globalData,
	viewFilter,
	editRCVariableDisclosure,
	deleteRCKeyDisclosure,
	setDeleteRCKeyData,
	multiFunction,
	segmentationData,
}: RemoteConfigTableProps) {
	return (
		<TableContainer>
			<Table variant="simple" size="md" border={'0px'}>
				<Thead>
					<Tr>
						<Th width="150px">Name</Th>
						<Th width="200px">Description</Th>
						<Th width="200px">Value</Th>
						<Th width="150px">Type</Th>
						<Th width="250px">Filter</Th>
						<Th width="200px">Latest Update</Th>
						<Th width="150px">
							<Flex justify="center" align="center" width="100%" height="100%">
								Production
							</Flex>
						</Th>
						<Th width="150px">
							<Flex justify="center" align="center" width="100%" height="100%">
								Public
							</Flex>
						</Th>
						<Th width="150px">Status</Th>
						<Th width="50px">View</Th>
						<Th width="50px">Delete</Th>
					</Tr>
				</Thead>
				<Tbody>
					{globalData.remoteConfigValues &&
						globalData.remoteConfigValues
							.sort((a, b) => a.key.localeCompare(b.key))
							.map(
								(field, i) =>
									viewFilter.canDisplay(field) && (
										<TableRow
											key={i}
											field={field}
											globalData={globalData}
											editRCVariableDisclosure={editRCVariableDisclosure}
											deleteRCKeyDisclosure={deleteRCKeyDisclosure}
											setDeleteRCKeyData={setDeleteRCKeyData}
											multiFunction={multiFunction}
											segmentationData={segmentationData}
										/>
									),
							)}
				</Tbody>
			</Table>
		</TableContainer>
	);
}

interface TableRowProps {
	field: RemoteConfigValue;
	globalData: GlobalStore;
	editRCVariableDisclosure: any;
	deleteRCKeyDisclosure: any;
	setDeleteRCKeyData: (data: any) => void;
	multiFunction: (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => void;
	segmentationData: PlayerSegment[];
}

function TableRow({
	field,
	globalData,
	editRCVariableDisclosure,
	deleteRCKeyDisclosure,
	setDeleteRCKeyData,
	multiFunction,
	segmentationData,
}: TableRowProps) {
	return (
		<Tr>
			<Td width="150px">{field.key}</Td>
			<Td
				width={'200px'}
				maxW={'200px'}
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				position="relative"
				_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
			>
				{field.description}
				<Box
					position="absolute"
					bg="gray.700"
					color="white"
					p="2"
					borderRadius="md"
					visibility="hidden"
					_groupHover={{ visibility: 'visible' }}
					zIndex="tooltip"
				>
					{field.description}
				</Box>
			</Td>
			<Td width="200px">
				<Text maxW="200px" isTruncated>
					{String(field.value)}
				</Text>
			</Td>
			<Td width="150px">{RemoteConfigTypeToString(field.type)}</Td>
			<Td width="250px">
				{field.filterOperations.length > 0 && (
					<Textarea minW="250px" fontSize={'sm'} border={'0px'}>
						{FilterToString(field.filterOperations, segmentationData)}
					</Textarea>
				)}
			</Td>
			<Td width="200px">{formatTimestamp(field.lastUpdated)}</Td>
			<Td width="150px">{showCheckMark(!field.isTest)}</Td>
			<Td width="150px">{showCheckMark(!field.isPersonal)}</Td>
			<Td width="150px">
				<Button
					onClick={() => toggleRCValueStatus(globalData, field.id)}
					leftIcon={
						<Icon
							as={field.isActive ? BsFillCheckCircleFill : CgCloseO}
							color={field.isActive ? 'green.200' : 'red.200'}
							_hover={{ color: 'gray.300' }}
						/>
					}
					variant="simple"
				/>
			</Td>
			<Td width="50px">
				<ViewButton onOpen={() => multiFunction(editRCVariableDisclosure, field)} />
			</Td>
			<Td width="50px">
				<Button
					onClick={() => {
						setDeleteRCKeyData(field);
						deleteRCKeyDisclosure.onOpen();
					}}
					leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
					variant="simple"
				/>
			</Td>
		</Tr>
	);
}

function showCheckMark(isActive: boolean) {
	return (
		<Flex justify="center" align="center" width="100%" height="100%">
			<Icon as={isActive ? BsFillCheckCircleFill : CgCloseO} color={isActive ? 'green.200' : 'red.200'} _hover={{ color: 'gray.300' }} />
		</Flex>
	);
}

function ViewButton({ onOpen }: { onOpen: () => void }) {
	return (
		<Button
			onClick={onOpen}
			aria-label="View"
			leftIcon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
			backgroundColor={'transparent'}
			_hover={{ bg: 'transparent', color: 'white' }}
		/>
	);
}
