import {create} from 'zustand';
import {ABTest} from '@repo/alictus-common/types/ab_test';
import {ABTestVariant} from '@repo/alictus-common/types/ab_test';
import {RemoteConfigValue} from '@repo/alictus-common/types/remote_config';
import {User} from '@repo/alictus-common/types/user';
import {UserDevice} from '@repo/alictus-common/types/user';


export type GlobalStore = {
    gameId: string;
    mainPackageId: number;
    setGameId: (gameId: string) => void;
    JWTToken: string | null;
    setJWTToken: (token: string) => void;
    user: User | null;
    userDevices: UserDevice[];
    setUser: (user: User) => void;
    abTestModal: ABTest | null;
    resetSelectedABTest: () => void;
    remoteABTests: ABTest[] | null;
    setRemoteABTests: (tests: ABTest[]) => void;
    updateAbTestModal: (test: Partial<ABTest>) => void;
    updateAbTestModalVariant: (index: number, test: Partial<ABTestVariant>) => void;
    updateAbTestModalVariantRCValue: (variantIndex: number, rcIndex: number, test: Partial<RemoteConfigValue>) => void;
    deleteABTestModalVariant: (index: number) => void;
    setABTestModal: (test: ABTest) => void;
    remoteConfigValues: RemoteConfigValue[];
    setRemoteConfigValues: (values: RemoteConfigValue[]) => void;
    selectedRemoteConfigValue: RemoteConfigValue | null;
    setSelectedRemoteConfigValue: (value: RemoteConfigValue) => void;
}

const globalStore = create<GlobalStore>((set) => ({
    abTestModal: null,
    gameId: "com.ck.tripletiles",
    mainPackageId: 1,
    setGameId: (gameId: string) => set({gameId: gameId}),
    JWTToken: "myjwt",
    setJWTToken: (token: string) => set({JWTToken: token}),
    user: null,
    userDevices: [],
    setUser: (user: User) => set({user: user}),
    remoteABTests: [],
    setRemoteABTests: (tests: ABTest[]) => set({remoteABTests: tests}),
    resetRemoteABTests: () => set({remoteABTests: []}),
    setABTestModal: (test: ABTest) => set({abTestModal: test}),
    updateAbTestModal: (test: Partial<ABTest>) => set((state) => ({abTestModal: {...(state.abTestModal ?? {} as ABTest), ...test}})),
    updateAbTestModalVariant: (index: number, test: Partial<ABTestVariant>) => set((state) => {
        const variants = state.abTestModal?.variants ?? [];
        const variant = variants[index] ?? {} as ABTestVariant;
        variants[index] = {...variant, ...test};
        return {abTestModal: {...(state.abTestModal ?? {} as ABTest), variants}};
    }),
    updateAbTestModalVariantRCValue: (variantIndex: number, rcIndex: number, test: Partial<RemoteConfigValue>) => set((state) => {
        const variants = state.abTestModal?.variants ?? [];
        const variant = variants[variantIndex] ?? {} as ABTestVariant;
        const rcValues = variant.values ?? [];
        const rcValue = rcValues[rcIndex] ?? {} as RemoteConfigValue;
        rcValues[rcIndex] = {...rcValue, ...test};
        variant.values = rcValues;
        variants[variantIndex] = variant;
        return {abTestModal: {...(state.abTestModal ?? {} as ABTest), variants}};
    }),
    deleteABTestModalVariant: (index: number) => set((state) => {
        const variants = state.abTestModal?.variants ?? [];
        variants.splice(index, 1);
        return {abTestModal: {...(state.abTestModal ?? {} as ABTest), variants}};
    }),
    resetSelectedABTest: () => set({abTestModal: null}),
    remoteConfigValues: [],
    setRemoteConfigValues: (values: RemoteConfigValue[]) => set({remoteConfigValues: values}),
    resetRemoteConfigValues: () => set({remoteConfigValues: []}),
    selectedRemoteConfigValue: null,
    setSelectedRemoteConfigValue: (value: RemoteConfigValue) => set({selectedRemoteConfigValue: value})

}));


export default globalStore;
