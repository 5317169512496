import React, { useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Input,
	Text,
	VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import { Formik, Form, Field } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import updatePasswordFormSchema from '@repo/alictus-common/validation/update_password_schema';
import { UpdatePasswordType } from '@repo/alictus-common/types/login/update_password';

const UpdatePasswordModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
	const [successMessage, setSuccessMessage] = useState<string>('');

	const resetFields = () => {
		setSuccessMessage('');
	};

	const handleClose = () => {
		resetFields();
		onClose();
	};

	const handleUpdatePassword = async (values: UpdatePasswordType, { setSubmitting, setErrors, resetForm }: any) => {
		try {
			const userInfo = sessionStorage.getItem('dashboardUserInfo');
			if (!userInfo) throw new Error('User info not found in session storage.');

			const user = JSON.parse(userInfo);
			const userId = user.id;
			const token = user.token;

			const response = await axios.post(
				`${config.API_ENDPOINT}/user/${userId}/updatePassword`,
				{ currentPassword: values.currentPassword, newPassword: values.newPassword },
				{ headers: { Authorization: `Bearer ${token}` } },
			);

			if (response.status === 200) {
				resetForm();
				setSuccessMessage('Password updated successfully!');
			} else {
				setErrors({ currentPassword: response.data.message });
			}
		} catch (e: any) {
			setErrors({ currentPassword: e.response?.data?.message || 'An error occurred. Please try again.' });
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Update Password</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Formik
						initialValues={{
							currentPassword: '',
							newPassword: '',
							confirmPassword: '',
						}}
						validationSchema={toFormikValidationSchema(updatePasswordFormSchema)}
						onSubmit={handleUpdatePassword}
					>
						{({ errors, touched, isSubmitting }) => (
							<Form>
								<VStack spacing={3}>
									<Field name="currentPassword">
										{({ field }: any) => <Input {...field} placeholder="Current Password" type="password" />}
									</Field>
									{touched.currentPassword && errors.currentPassword && (
										<Text color="red.500" fontSize="sm">
											{errors.currentPassword}
										</Text>
									)}

									<Field name="newPassword">{({ field }: any) => <Input {...field} placeholder="New Password" type="password" />}</Field>
									{touched.newPassword && errors.newPassword && (
										<Text color="red.500" fontSize="sm">
											{errors.newPassword}
										</Text>
									)}

									<Field name="confirmPassword">
										{({ field }: any) => <Input {...field} placeholder="Confirm New Password" type="password" />}
									</Field>
									{touched.confirmPassword && errors.confirmPassword && (
										<Text color="red.500" fontSize="sm">
											{errors.confirmPassword}
										</Text>
									)}

									{successMessage && (
										<Text color="green.500" fontSize="sm">
											{successMessage}
										</Text>
									)}
								</VStack>
								<ModalFooter mt={4}>
									<Button colorScheme="blue" mr={3} isLoading={isSubmitting} type="submit">
										Update Password
									</Button>
									<Button variant="ghost" onClick={handleClose}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default UpdatePasswordModal;
