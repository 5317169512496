import React, { useEffect, useState } from 'react';
import {
	Button,
	Box,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';
import { DataSelector, PlayerSegment } from '@repo/alictus-common/types/segment';
import { SegmentationFilterOperation } from '@repo/alictus-common/types/runtime/filter_operation';
import { FilterCondition, FilterType } from '@repo/alictus-common/enums/filter_condition';
import { DateFilter } from '@repo/alictus-common/enums/date_filter';
import segmentationSchema from '@repo/alictus-common/validation/segmentation_schema';

// import { toFormikValidationSchema } from 'zod-formik-adapter';

const createEmptyUserSegment = (): PlayerSegment => {
	return {
		id: 0,
		uid: '',
		name: '',
		description: '',
		filterOperations: [],
		userId: 0,
		creationDate: 0,
		lastUpdated: 0,
		isTest: false,
		isDeleted: false,
		isOverWritten: false,
	};
};

export function SegmentModal({
	title,
	isOpen,
	onClose,
	segmentInstance,
}: {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	segmentInstance?: PlayerSegment;
}) {
	let globalData = globalStore();
	const toast = useToast();
	const [playerSegment, setPlayerSegment] = useState<PlayerSegment>(createEmptyUserSegment());
	const [dataSelectors, setDataSelectors] = useState<DataSelector[]>([]);
	const [segmentFilter, setSegmentFilter] = useState<SegmentationFilterOperation>({
		Filter: FilterType.Segmentation,
		Condition: 0,
		Values: [],
		SelectorUid: '',
		KeyToFetch: '',
		DateFilter: 0,
	});
	const [showSubmitButton, setShowSubmitButton] = useState(true);
	const [isDateSelector, setIsDateSelector] = useState(false);

	const fetchDataSelectors = async () => {
		let bearerToken = 'Bearer ' + globalData.JWTToken;
		let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
		await axios.get(url, { headers: { Authorization: bearerToken } }).then((response) => {
			setDataSelectors(response.data as DataSelector[]);
		});
	};

	useEffect(() => {
		const fetchData = async () => {
			if (isOpen) {
				if (segmentInstance) {
					setPlayerSegment(segmentInstance);
				}
				await fetchDataSelectors(); // Await the fetchDataSelectors function
			}
		};

		fetchData();
	}, [isOpen, segmentInstance]);

	const resetForm = () => {
		setPlayerSegment(createEmptyUserSegment());
		setDataSelectors([]);
		setSegmentFilter({
			Filter: FilterType.Segmentation,
			Condition: 0,
			Values: [],
			SelectorUid: '',
			KeyToFetch: '',
			DateFilter: 0,
		});
	};

	function updatePlayerSegment(playerSegmentField: Partial<PlayerSegment>) {
		setPlayerSegment({ ...playerSegment, ...playerSegmentField });
	}

	function addFilterOperation(filterOperation: SegmentationFilterOperation) {
		let newFilterOperations = [...playerSegment.filterOperations, filterOperation];
		setPlayerSegment({ ...playerSegment, ...{ filterOperations: newFilterOperations } });
	}

	function removeFilterOperation(index: number) {
		let newFilterOperations = playerSegment.filterOperations.filter((_, i) => i !== index);
		setPlayerSegment({ ...playerSegment, ...{ filterOperations: newFilterOperations } });
	}

	function updateFilterOperation(index: number, filterOperationField: Partial<SegmentationFilterOperation>) {
		console.log('Update Filter Operation', index, filterOperationField);

		if (index === -1) {
			// Safely update the current segment filter
			setSegmentFilter((prevSegmentFilter) => ({
				...prevSegmentFilter,
				...filterOperationField,
			}));
			return;
		}

		// Update an existing filter operation in the playerSegment
		setPlayerSegment((prevPlayerSegment) => {
			const updatedFilterOperations = [...prevPlayerSegment.filterOperations];
			updatedFilterOperations[index] = {
				...updatedFilterOperations[index],
				...filterOperationField,
			};
			return {
				...prevPlayerSegment,
				filterOperations: updatedFilterOperations,
			};
		});
	}

	function updateFilterCondition(index: number, condition: number): void {
		console.log('Update Filter Condition', index, condition);
		if (index === -1) {
			setSegmentFilter({ ...segmentFilter, ...{ Condition: condition as unknown as FilterCondition } });
			return;
		}
		console.log('Updating already added filter condition', condition);
		let filterOp = playerSegment.filterOperations;
		filterOp[index].Condition = condition as unknown as FilterCondition;
		setPlayerSegment({ ...playerSegment, ...{ filterOperations: filterOp } });
	}

	const handleSubmit = async (event?: React.FormEvent) => {
		if (event) event.preventDefault();
		setShowSubmitButton(false);
		console.log('Validating Segment', playerSegment);
		const validationResult = segmentationSchema.safeParse(playerSegment);
		if (!validationResult.success) {
			console.error('Validation failed:', validationResult.error.issues);

			// Prepare the validation error messages
			const errorMessages = validationResult.error.issues.map((issue) => `${issue.message}`);

			// Show error toast with line breaks
			toast({
				title: 'Validation Error',
				description: (
					<Box>
						{errorMessages.map((msg, idx) => (
							<Box key={idx}>- {msg}</Box>
						))}
					</Box>
				),
				status: 'error',
				duration: 7000,
				isClosable: true,
				position: 'top',
			});

			setShowSubmitButton(true);
			return;
		}

		// Show success toast
		toast({
			title: 'Validation Passed',
			description: 'The configuration has been validated successfully.',
			status: 'success',
			duration: 3000,
			isClosable: true,
		});

		setShowSubmitButton(true);
		console.log('Validation passed');
		if (segmentInstance) {
			await updateSegmentInstanceRequest();
		} else {
			await createNewSegmentInstanceRequest();
		}
	};

	const createNewSegmentInstanceRequest = async () => {
		try {
			console.log('Creating Segment', playerSegment);
			setShowSubmitButton(false);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
			await axios.post(url, playerSegment, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
				handleClose();
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Creating Segment',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setShowSubmitButton(true);
		}
	};

	const updateSegmentInstanceRequest = async () => {
		try {
			setShowSubmitButton(false);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + `/segment/${segmentInstance?.id}`;
			await axios.put(url, playerSegment, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
				handleClose();
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Updating Selector',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setShowSubmitButton(true);
		}
	};

	const handleClose = () => {
		setShowSubmitButton(false);
		resetForm();
		onClose();
	};

	const addSegmentFilter = () => {
		addFilterOperation(segmentFilter);
		setSegmentFilter({
			Filter: FilterType.Segmentation,
			Condition: 0,
			Values: [],
			SelectorUid: '',
			KeyToFetch: '',
			DateFilter: 0,
		});
	};

	const removeSegmentFilter = (index: number) => {
		removeFilterOperation(index);
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent maxW="950px" border={'1px'} borderColor={'gray.700'}>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={handleSubmit}>
					<ModalBody>
						<Stack spacing={4}>
							<FormControl>
								<FormLabel>Name</FormLabel>
								<Input value={playerSegment.name} onChange={(event) => updatePlayerSegment({ name: event.target.value })} />
							</FormControl>
							<FormControl>
								<FormLabel>Description</FormLabel>
								<Input value={playerSegment.description} onChange={(event) => updatePlayerSegment({ description: event.target.value })} />
							</FormControl>
							{playerSegment.filterOperations.map((playerSegmentFilterOperation, index) => (
								<HStack spacing={4}>
									<Select
										placeholder="Select Selector"
										value={playerSegmentFilterOperation.SelectorUid}
										onChange={(e) => {
											updateFilterOperation(index, {
												SelectorUid: e.target.value,
											});

											console.log('Filter Operation', playerSegmentFilterOperation);
										}}
									>
										{dataSelectors.map((selector) => (
											<option key={selector.uid} value={selector.uid}>
												{selector.name}
											</option>
										))}
									</Select>
									<Select
										placeholder="Select Condition"
										value={playerSegmentFilterOperation.Condition}
										onChange={(e) => updateFilterCondition(index, parseInt(e.target.value))}
									>
										{Object.entries(FilterCondition)
											.filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
											.map(([key, condition]) => (
												<option key={key} value={condition}>
													{key}
												</option>
											))}
									</Select>
									{(dataSelectors.find((selector) => selector.uid === playerSegmentFilterOperation.SelectorUid)?.isDate || false) && (
										<Select
											//placeholder="Select Date Filter"
											value={playerSegmentFilterOperation.DateFilter}
											onChange={(e) => updateFilterOperation(index, { DateFilter: parseInt(e.target.value) })}
										>
											{Object.entries(DateFilter)
												.filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
												.map(([key, condition]) => (
													<option key={key} value={condition} disabled={key === 'MinutesSince'}>
														{key}
													</option>
												))}
										</Select>
									)}
									<Input
										value={playerSegmentFilterOperation.Values.toString()}
										onChange={(e) => updateFilterOperation(index, { Values: [e.target.value] })}
										placeholder="Filter Value"
									/>
									<Button width={'350px'} minW={'115px'} colorScheme="red" onClick={() => removeFilterOperation(index)}>
										Remove Filter
									</Button>
								</HStack>
							))}
							<HStack spacing={4}>
								<Select
									placeholder="Select Selector"
									value={segmentFilter.SelectorUid}
									onChange={(e) => {
										updateFilterOperation(-1, { SelectorUid: e.target.value });
										setIsDateSelector(dataSelectors.find((selector) => selector.uid === e.target.value)?.isDate || false);
									}}
								>
									{dataSelectors.map((selector) => (
										<option key={selector.uid} value={selector.uid}>
											{selector.name}
										</option>
									))}
								</Select>
								<Select
									placeholder="Select Condition"
									value={segmentFilter.Condition}
									onChange={(e) => updateFilterCondition(-1, parseInt(e.target.value))}
								>
									{Object.entries(FilterCondition)
										.filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
										.map(([key, condition]) => (
											<option key={key} value={condition}>
												{key}
											</option>
										))}
								</Select>
								{isDateSelector && (
									<Select
										value={segmentFilter.DateFilter}
										onChange={(e) => updateFilterOperation(-1, { DateFilter: parseInt(e.target.value) })}
									>
										{Object.entries(DateFilter)
											.filter(([key, condition]) => isNaN(Number(key))) // Filter out numeric keys
											.map(([key, condition]) => (
												<option key={key} value={condition} disabled={key === 'MinutesSince'}>
													{key}
												</option>
											))}
									</Select>
								)}
								<Input
									value={segmentFilter.Values.toString()}
									onChange={(e) => updateFilterOperation(-1, { Values: [e.target.value] })}
									placeholder="Filter Value"
								/>
								<Button width={'350px'} minW={'115px'} colorScheme="blue" onClick={addSegmentFilter}>
									Add Filter
								</Button>
							</HStack>
						</Stack>
						<ModalFooter padding={'20px'}>
							<Button variant="outline" mr={3} onClick={onClose}>
								Cancel
							</Button>
							{segmentInstance ? (
								<Button colorScheme="blue" isLoading={!showSubmitButton} type="submit">
									{' '}
									Update{' '}
								</Button>
							) : (
								<Button colorScheme="blue" isLoading={!showSubmitButton} type="submit">
									{' '}
									Create{' '}
								</Button>
							)}
						</ModalFooter>
					</ModalBody>
				</form>
			</ModalContent>
		</Modal>
	);
}

export default SegmentModal;
