import { z } from 'zod';
import { RCType } from '../types';
import { Platform } from '../types';
import { FilterCondition } from '../types/enums/filter_condition';
import { FilterType } from '../types/enums/filter_type';

const remoteConfigSchema = z
	.object({
		key: z.string().min(3, 'Name must be at least 3 characters'),
		description: z.string().min(3, 'Description must be at least 3 characters'),
		type: z.nativeEnum(RCType),
		value: z.union([z.string(), z.boolean(), z.number()]),
		isPersonal: z.boolean(),
		isTest: z.boolean().default(true),
		platform: z.nativeEnum(Platform),
		filterOperations: z.array(
			z.object({
				Filter: z.nativeEnum(FilterType),
				Condition: z.nativeEnum(FilterCondition),
				Values: z.array(z.string()),
			}),
		),
	})
	.superRefine((data, ctx) => {
		const { type, value } = data;

		if (type === RCType.String) {
			if (typeof value !== 'string') {
				ctx.addIssue({
					code: z.ZodIssueCode.invalid_type,
					expected: 'string',
					received: typeof value,
					path: ['variableValue'],
					message: 'RC value must be a string',
				});
				return;
			}
			if (value.length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.too_small,
					path: ['variableValue'],
					message: 'RC value is required',
					minimum: 1,
					type: 'string',
					inclusive: true,
				});
				return;
			}
			if (value.startsWith('{')) {
				try {
					JSON.parse(value);
				} catch (e) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						path: ['variableValue'],
						message: 'Invalid JSON string',
					});
					return;
				}
			}
		} else if (type === RCType.Boolean) {
			if (typeof value !== 'boolean') {
				ctx.addIssue({
					code: z.ZodIssueCode.invalid_type,
					expected: 'boolean',
					received: typeof value,
					path: ['variableValue'],
					message: 'RC value must be a boolean',
				});
			}
		} else if (type === RCType.Integer) {
			if (typeof value !== 'number' || !Number.isInteger(value)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['variableValue'],
					message: 'RC value must be an integer',
				});
			}
		} else if (type === RCType.Float) {
			if (typeof value !== 'number') {
				ctx.addIssue({
					code: z.ZodIssueCode.invalid_type,
					expected: 'number',
					received: typeof value,
					path: ['variableValue'],
					message: 'RC value must be a number',
				});
			}
		} else {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['variableType'],
				message: 'Invalid variable type',
			});
		}
	})
	.superRefine((data, ctx) => {
		const { filterOperations } = data;

		filterOperations.forEach((filterOperation, index) => {
			const { Filter, Condition, Values } = filterOperation;
			if (Filter === FilterType.Version) {
				if (Values.length !== 0 && Condition === FilterCondition.NoOp) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Version filter must be selected',
					});
				}

				let splitted_version = Values[0].split('.');
				if (
					splitted_version.length !== 3 ||
					splitted_version.some((v) => !/^\d+$/.test(v)) ||
					splitted_version.some((v) => parseInt(v) < 0)
				) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Version must be in the format x.y.z where x, y, z are nonnegative integers',
						path: ['version'],
					});
				}
			}

			if (Filter === FilterType.Country) {
				if (Values.length === 0 && Condition !== FilterCondition.NoOp) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'At least one country must be selected',
					});
				}

				if (Values.length > 0 && Condition === FilterCondition.NoOp) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Country filter must be selected',
					});
				}
			}

			if (Filter === FilterType.Segmentation) {
				if (Values.length === 0 && Condition !== FilterCondition.NoOp) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'At least one segment must be selected',
					});
				}

				if (Values.length > 0 && Condition === FilterCondition.NoOp) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Segmentation filter must be selected',
					});
				}
			}
		});
	});

export default remoteConfigSchema;
