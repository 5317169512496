import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    HStack,
    VStack,
    useToast
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from "../store";
import {PlayerSegment} from "@repo/alictus-common/types/segment";


export function DeleteSegmentModal({segmentInstance, disclosure}: {segmentInstance: PlayerSegment; disclosure: {isOpen: boolean; onClose: () => void}}) {
    const [isDeleting, setIsDeleting] = React.useState(false);
    let globalData = globalStore();
    const toast = useToast();

    const sendDeleteRequest = async (segmentId: number) => {
        let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment/' + segmentId;
        try {
            await axios.delete(url, {headers: {Authorization: "Bearer " + globalData.JWTToken}});
            disclosure.onClose();
        } catch (error:any) {
            toast({
                title: "Error Deleting Segment",
                description: "The following remote config values are using this segment " + error.response?.data,
                status: "error",
                duration: 20000,
                isClosable: true,
                position: "top"
            });
        } finally {
            setIsDeleting(false);
        }
    };

    const handleDelete = () => {
        setIsDeleting(true);
        sendDeleteRequest(segmentInstance.id);
    };

    return (
        <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Delete {segmentInstance.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    <VStack>
                        <HStack>
                            <Button variant="outline" mr={3} onClick={disclosure.onClose}>Cancel</Button>
                            {isDeleting ? (
                                <Spinner />
                            ) : (
                                <Button variant="outline" bgColor={'red.400'} mr={3} onClick={() => {
                                    handleDelete();
                                }}>Proceed</Button>
                            )}
                        </HStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DeleteSegmentModal;
