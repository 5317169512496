import { useEffect, useCallback } from 'react';
import axios from 'axios';
import globalStore from '../store';
import config from '../config';

const usePeriodicJwtRefresh = (refreshInterval = 60000 * 30) => {
  const { JWTToken, setJWTToken } = globalStore();

  const refreshToken = useCallback(async () => {
    try {
      console.log('Refreshing JWT...');
      const response = await axios.get(
        `${config.CLOUDFLARE_LOCAL_ENDPOINT}/renewAccessToken`,
        {
          headers: { Authorization: `Bearer ${JWTToken}` }
        }
      );
      console.log(response);
      if (response.data && response.data.renewedToken) {
        console.log('Refreshed JWT:', response.data.renewedToken);
        setJWTToken(response.data.renewedToken);
      }
    } catch (error) {
      console.error('Failed to refresh JWT:', error);
    }
  }, [JWTToken, setJWTToken]);

  useEffect(() => {
    const intervalId = setInterval(refreshToken, refreshInterval);

    //Reset interval on unmount
    return () => clearInterval(intervalId);
  }, [refreshToken, refreshInterval]);

  return { refreshToken };
};

export default usePeriodicJwtRefresh;