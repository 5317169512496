import { useState, useEffect } from 'react';
import { RemoteConfigValue } from '@repo/alictus-common/types';
import { sortVersions } from '../utils/version_utils';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

export function useUniqueValues(remoteConfigValues: RemoteConfigValue[], segmentationData: PlayerSegment[]) {
	const [availableVersions, setAvailableVersions] = useState<string[]>([]);
	const [availableCountries, setAvailableCountries] = useState<string[]>([]);
	const [availableSegmentations, setAvailableSegmentations] = useState<string[]>([]);

	useEffect(() => {
		const versions = getUniqueVersions(remoteConfigValues);
		const countries = getUniqueCountries(remoteConfigValues);
		const segmentations = getUniqueSegmentations(remoteConfigValues, segmentationData);

		setAvailableVersions(versions);
		setAvailableCountries(countries);
		setAvailableSegmentations(segmentations);
	}, [remoteConfigValues, segmentationData]);

	return { availableVersions, availableCountries, availableSegmentations };
}

function getUniqueVersions(remoteConfigValues: RemoteConfigValue[]): string[] {
	if (!remoteConfigValues) return [];

	const uniqueVersions = new Set<string>();

	remoteConfigValues.forEach((value) => {
		value.filterOperations.forEach((filterOp) => {
			if (filterOp.Filter === 2) {
				// FilterType.Version
				filterOp.Values?.forEach((v) => uniqueVersions.add(v.toString()));
			}
		});
	});

	const sortedVersions = sortVersions(Array.from(uniqueVersions));
	sortedVersions.unshift('Select Version');
	return sortedVersions;
}

function getUniqueCountries(remoteConfigValues: RemoteConfigValue[]): string[] {
	if (!remoteConfigValues) return [];

	const uniqueCountries = new Set<string>();

	remoteConfigValues.forEach((value) => {
		value.filterOperations.forEach((filterOp) => {
			if (filterOp.Filter === 1) {
				// FilterType.Country
				filterOp.Values?.forEach((v) => uniqueCountries.add(v.toString()));
			}
		});
	});

	const sortedCountries = Array.from(uniqueCountries).sort();
	sortedCountries.unshift('Select Country');
	return sortedCountries;
}

function getUniqueSegmentations(remoteConfigValues: RemoteConfigValue[], segmentationData: PlayerSegment[]): string[] {
	if (!remoteConfigValues) return [];

	const uniqueSegmentations = new Set<string>();

	/*remoteConfigValues.forEach((value) => {
		value.filterOperations.forEach((filterOp) => {
			if (filterOp.Filter === 11) {
				// FilterType.Segmentation
				filterOp.Values?.forEach((v) => uniqueSegmentations.add(segmentationData.find((s) => s.uid === v)?.name || v.toString()));
			}
		});
	});*/

	segmentationData.forEach((segmentation) => {
		uniqueSegmentations.add(segmentation.name);
	});

	const sortedSegmentations = Array.from(uniqueSegmentations).sort();
	sortedSegmentations.unshift('Select Segmentation');
	return sortedSegmentations;
}
