export function sortVersions(versions: string[]): string[] {
    return versions.sort((a: string, b: string) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || 0;
            const bPart = bParts[i] || 0;

            if (aPart < bPart) return -1;
            if (aPart > bPart) return 1;
        }

        return 0;
    });
}
