import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { PlusSquareIcon } from '@chakra-ui/icons';

interface PlusButtonProps {
    paddingTop?: string;
    onOpen: () => void;
}

export function PlusButton({ paddingTop, onOpen }: PlusButtonProps) {
    return (
        <IconButton
            onClick={onOpen}
            paddingTop={paddingTop}
            aria-label='Add'
            icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
            backgroundColor={'transparent'}
            _hover={{ bg: 'transparent', color: 'white' }}
        />
    );
}
